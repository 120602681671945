































































import FileService from '@/services/FileService';
import RemoteServices from '@/services/RemoteServices';
import { parseAsync, transforms } from 'json2csv';
import { Component, Vue } from 'vue-property-decorator';
import XmlViewer from 'vue-xml-viewer';

@Component({ components: { XmlViewer } })
export default class Record extends Vue {
    id(): string {
        return this.$attrs.id.split(':')[2];
    }

    key(): string {
        return this.id().split('/')[1];
    }

    async downloadXML(): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            FileService.download(
                this.$attrs.xml,
                'metadata_cenote_' + this.key() + '.xml',
                'text/xml',
            );
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }

    async downloadJSON(): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            const cenote = await RemoteServices.getCenote(this.key());
            FileService.download(
                JSON.stringify(cenote),
                'cenote_' + this.key() + '.json',
                'application/json',
            );
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }

    // TODO: Get this from backend
    async downloadCSV(): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            const cenote = await RemoteServices.getCenote(this.key());

            const fields = Object.keys(transforms.flatten()(cenote));
            const csv = await parseAsync(cenote, {
                fields,
                transforms: [
                    transforms.flatten(),
                    transforms.unwind({
                        paths: [
                            'properties.alternative_names',
                            'properties.issues',
                            'properties.contacts',
                        ],
                        blankOut: true,
                    }),
                    transforms.flatten(),
                ],
            });
            FileService.download(
                csv,
                'cenote_' + this.key() + '.csv',
                'text/csv',
            );
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }
}
